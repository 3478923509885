#accessibility,
#links,
#privacy,
#terms {
    text-align: justify;

    @include media-breakpoint-up(lg) {
        text-align: start;
    }
}
