#fields {
    position: relative;
    padding: 0;
    flex: 1;
    display: flex;

    &:before {
        content: '';
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        opacity: 0.15;
        position: absolute;
        background: url('../../images/areas_bg.png') no-repeat fixed center;

        @include media-breakpoint-up(xl) {
            background-size: contain;
        }
    }

    .container {
        display: flex;
    }

    .fields-wrapper {
        padding: 20px 0;
        width: 100%;
        background-color: #fffefe99;

        @include media-breakpoint-up(md) {
            width: fit-content;
            padding: 20px;
        }

        .accordion {
            margin-top: 20px;

            @include media-breakpoint-up(lg) {
                min-width: 360px;
            }

            &-item {
                border: none;
            }

            &-header {
                border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
                border-radius: var(--bs-accordion-border-radius);
                background-color: var(--color-white);
            }

            &-button {
                font: var(--p-l-mobile);
                border-radius: 5px;

                &:not(.collapsed) {
                    color: unset;
                    background-color: unset;
                    box-shadow: none;
                }

                @include media-breakpoint-up(lg) {
                    font-size: 24px;
                }
            }

            &-body {
                p, li {
                    font-size: 14px;

                    @include media-breakpoint-up(lg) {
                        font-size: 18px;
                    }
                }
            }
        }
    }
}


