#lawyer {
    h2 {
        font-style: italic;
    }

    svg {
        margin-right: 5px;
    }

    img {
        border-radius: 10px;
    }

    .breadcrumbs {
        margin-bottom: 15px;

        @include media-breakpoint-up(md) {
            margin-bottom: 25px
        }
    }

    .info > * {
        margin-bottom: 6px;
    }

    .no-whats {
        margin-left: 22px;

        @include media-breakpoint-up(lg) {
            margin-left: 25px;
        }
    }

    .text {
        @include media-breakpoint-up(md) {
            padding-left: 30px;
        }
    }
}
