/* Layout */

$grid-columns: 12;
$grid-gutter-width: 12px;

$grid-breakpoints: (
    xs: 0,
    sm: 575px,
    md: 767px,
    lg: 991px,
    xl: 1439px,
    xxl: 1559px
);

$container-max-widths: (
    sm: 556px,
    md: 728px,
    lg: 998px,
    xl: 1240px,
    xxl: 1520px
);
