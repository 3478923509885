#homeArticles {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(md) {
        h2 {
            text-align: center;
        }
    }

    .goto-articles {
        font: var(--font-weight-semibold) 16px/26px var(--header-font);
        margin-left: auto;
        margin-bottom: 6px;
    }

    .articles-wrapper {
        display: flex;
        justify-content: space-between;
        padding: 0 10px;

        @include media-breakpoint-down(md) {
            > :not(:first-child) {
                display: none;
            }
        }

        @include media-breakpoint-up(md) {
            .col-md-6 {
                width: 49%;
            }
        }

        .card {
            &-img-top {
                border-radius: 10px 10px 0 0;
                aspect-ratio: 18/7;
                object-fit: cover;
            }

            &-body {
                &--text {
                    display: -webkit-box;
                    text-align: justify;
                    overflow: hidden;
                    -webkit-line-clamp: 8;
                    -webkit-box-orient: vertical;

                    @include media-breakpoint-up(md) {
                        -webkit-line-clamp: 6;
                        text-align: start;
                    }

                    @include media-breakpoint-up(xl) {
                        -webkit-line-clamp: 8;
                    }
                }
            }

            &-bottom__url {
                font-size: 16px;

                @include media-breakpoint-up(lg) {
                    font-size: 18px;
                }
            }
        }
    }
}
