@font-face {
    font-family: 'Crimson Text';
    font-weight: 400;
    src: url(../../../fonts/CrimsonText-Regular.ttf);
}

@font-face {
    font-family: 'Crimson Text';
    font-weight: 600;
    src: url(../../../fonts/CrimsonText-Bold.ttf);
}

@font-face {
    font-family: 'Crimson Text';
    font-weight: 600;
    font-style: italic;
    src: url(../../../fonts/CrimsonText-BoldItalic.ttf);
}

@font-face {
    font-family: 'Crimson Text';
    font-style: italic;
    src: url(../../../fonts/CrimsonText-Italic.ttf);
}

@font-face {
    font-family: 'Chivo';
    src: url(../../../fonts/Chivo-VariableFont_wght.ttf);
}

:root {
    /* Typography */
    --font-weight-light: 300;
    --font-weight-regular: 400;
    --font-weight-medium: 500;
    --font-weight-semibold: 600;
    --font-weight-bold: 700;

    /* Heading Fonts and Colors */
    --header-font: 'Crimson Text', Arial;
    --header-color-primary: var(--color-primary);
    --header-color-primary-hover: var(--color-primary-hover);
    --header-color-1: var(--color-base);
    --header-color-1-invert: var(--color-white);

    /* Body Fonts and Colors */
    --body-font: 'Chivo', Arial;
    --body-color: var(--color-text);
    --link-color: var(--color-secondary);
    --link-color-hover: var(--color-tertiary);
    --link-decoration: none;
    --link-decoration-hover: underline;
    --tag-color: var(--color-gray);


    /* headline1 */
    --h-1-desktop: var(--font-weight-semibold) 48px/60px var(--header-font);
    --h-1-tablet: var(--font-weight-semibold) 32px/42px var(--header-font);
    --h-1-mobile: var(--font-weight-semibold) 32px/42px var(--header-font);

    /* headline2 */
    --h-2-desktop: var(--font-weight-semibold) 42px/52px var(--header-font);
    --h-2-tablet: var(--font-weight-semibold) 28px/38px var(--header-font);
    --h-2-mobile: var(--font-weight-semibold) 28px/38px var(--header-font);

    /* headline3 */
    --h-3-desktop: var(--font-weight-semibold) 36px/46px var(--header-font);
    --h-3-tablet: var(--font-weight-semibold) 24px/32px var(--header-font);
    --h-3-mobile: var(--font-weight-semibold) 24px/32px var(--header-font);

    /* headline4 */
    --h-4-desktop: var(--font-weight-semibold) 28px/38px var(--header-font);
    --h-4-tablet: var(--font-weight-semibold) 22px/30px var(--header-font);
    --h-4-mobile: var(--font-weight-semibold) 22px/30px var(--header-font);

    /* headline5 */
    --h-5-desktop: var(--font-weight-semibold) 24px/34px var(--header-font);
    --h-5-tablet: var(--font-weight-semibold) 20px/28px var(--header-font);
    --h-5-mobile: var(--font-weight-semibold) 20px/28px var(--header-font);

    /* headline6 */
    --h-6-desktop: var(--font-weight-regular) 20px/28px var(--header-font);
    --h-6-tablet: var(--font-weight-regular) 18px/26px var(--header-font);
    --h-6-mobile: var(--font-weight-regular) 18px/26px var(--header-font);

    /* p-large */
    --p-l-desktop: var(--font-weight-regular) 18px/32px var(--body-font);
    --p-l-mobile: var(--font-weight-regular) 16px/26px var(--body-font);

    /* p-medium */
    --p-m-desktop: var(--font-weight-regular) 15px/23px var(--body-font);
    --p-m-mobile: var(--font-weight-regular) 13px/20px var(--body-font);

    /* p-small */
    --p-s-desktop: var(--font-weight-regular) 13px/18px var(--body-font);
    --p-s-mobile: var(--font-weight-regular) 11px/16px var(--body-font);

    /* footer */
    --heading-footer: var(--font-weight-semibold) 16px/22px var(--header-font);

    /* tag */
    --tag-font-mobile: var(--font-weight-semibold) 15px/26px var(--header-font);
    --tag-font-desktop: var(--font-weight-semibold) 16px/26px var(--header-font);
}

