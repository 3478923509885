#mvv {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(md) {
        flex-direction: row;
        justify-content: space-between;

        > * {
            width: 33%;
        }

        :first-child {
            img {
                border-radius: 10px 0 0 0;
            }
        }

        :last-child {
            img {
                border-radius: 0 10px 0 0;
            }
        }
    }

    img {
        aspect-ratio: 15/8;
        object-fit: cover;
        border-radius: 10px;
        margin-bottom: 5px;

        @include media-breakpoint-up(md) {
            border-radius: 0;
        }
    }

    ol {
        padding-left: 1rem;
    }

    .mvv-text {
        text-align: justify;

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            @include media-breakpoint-down(md) {
                text-align: center;
            }
        }

        @include media-breakpoint-up(md) {
            text-align: start;
            max-width: 90%;
            margin-left: 5px;
        }
    }

}
