:root {
    --color-base: #29292E;
    --color-primary: #FFD966;
    --color-primary-hover: #FFF3D2;
    --color-secondary: #29292E;
    --color-tertiary: #783636;
    --color-text: #060606;
    --color-white: #FFFEFE;
    --color-light: #FFFDF8;
    --color-icon: #424245;
    --color-gray: #999797;
    --color-border: #e3e1e1;
    --color-red: #dd2e45;
}
