.page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    main {
        padding: 20px 0;
    }

    footer {
        margin-top: auto;
    }
}

/* Firefox */
* {
    scrollbar-color: var(--color-base) transparent;
    scrollbar-width: thin;
}
/* Chrome, Edge, Safari */
::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track {
    background-color: var(--color-white);
}

::-webkit-scrollbar-thumb {
    background-color: var(--color-base);
    border-radius: 20px;
    border: 1px solid var(--color-white);
}
