footer {
    div {
        @extend %p-m;
    }

    p {
        margin: 0;
    }

    #footer-top {
        .container {
            padding-top: 15px;
            padding-bottom: 15px;
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            justify-content: space-between;

            @include media-breakpoint-up(md) {
                flex-direction: row;
            }

            a:hover path {
                color: var(--color-tertiary);
            }
        }

        a:hover {
            color: var(--color-tertiary);
            text-decoration: none;
        }

        .footer-column {
            display: flex;
            flex-direction: column;

            @include media-breakpoint-down(md) {
                &:not(:last-child) {
                    margin-bottom: 10px;
                }
            }

            @include media-breakpoint-up(md) {
                max-width: 24%;
            }

            @include media-breakpoint-up(lg) {
                max-width: 20%;
            }

            > *:not(:last-child):not(h6) {
                margin-bottom: 10px;
            }

            h6 {
                font-weight: var(--font-weight-semibold);
                margin-bottom: 6px;
            }

            &__address,
            &__contact {
                svg {
                    margin-right: 5px;
                }

                .no-whats {
                    margin-left: 22px;

                    @include media-breakpoint-up(lg) {
                        margin-left: 25px;
                    }
                }
            }

            &__links {
                @include media-breakpoint-down(md) {
                    display: none;
                }
            }

            &__social {
                @include media-breakpoint-up(md) {
                    align-items: center;
                }

                .footer-icon:not(:last-child) {
                    margin-right: 8px;
                }

                .logo {
                    width: 110px;
                    height: 110px;

                    @include media-breakpoint-down(md) {
                        display: none;
                    }

                    @include media-breakpoint-up(lg) {
                        width: 140px;
                        height: 140px;
                    }
                }
            }
        }
    }

    #footer-bottom {
        * {
            font: var(--header-font);
        }

        .container {
            padding-top: 15px;
            padding-bottom: 15px;
            display: flex;
            flex-direction: column;

            @include media-breakpoint-up(md) {
                align-items: center;
            }
        }

        .footer-links-wrapper {
            display: flex;
            flex-direction: column;

            a:not(:last-child) {
                margin-bottom: 8px;
            }

            @include media-breakpoint-up(md) {
                flex-direction: row;
                justify-content: center;

                a {
                    margin-bottom: 12px;

                    &:not(:last-child) {
                        margin-right: 25px;
                    }
                }
            }
        }
    }
}



