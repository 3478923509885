#article {
    padding: 0;

    .article {
        &--banner {
            margin-bottom: 10px;

            @include media-breakpoint-up(lg) {
                margin-bottom: 24px;
            }

            &__img {
                aspect-ratio: 21/9;
                object-fit: cover;

                @include media-breakpoint-up(lg) {
                    aspect-ratio: 27/9;
                }
            }
        }

        &--meta {
            display: flex;
            margin-bottom: 10px;

            @include media-breakpoint-up(lg) {
                margin-bottom: 20px;
            }

            .badges {
                display: flex;

                .badge {
                    font-family: var(--header-font);
                    color: var(--color-white);
                    font-weight: var(--font-weight-semibold);
                    margin-right: 5px;
                    line-height: unset;

                    &--tag {
                        color: var(--color-text);
                        background-color: var(--color-primary);
                    }

                    &--artigo {
                        background-color: var(--color-tertiary);
                    }

                    &--noticia {
                        background-color: var(--color-red);
                    }

                    &--palestra {
                        background-color: var(--color-base);
                    }
                }
            }

            .date {
                color: var(--color-gray);
            }
        }

    }

    p {
        text-align: justify;

        @include media-breakpoint-up(md) {
            text-align: start;
        }
    }
}
