#filterAndSort {
    width: 100%;
    display: flex;
    justify-content: space-between;

    @include media-breakpoint-up(lg) {
        flex-direction: column;
        justify-content: start;
        max-width: 20%;
    }

    .form-select {
        border-width: 1px;
        border-radius: 10px;
        --bs-form-select-bg-img: url('../../../images/caret-down-solid.svg');
        font-size: rem(13px);
    }

    #sort {
        @include media-breakpoint-up(lg) {
            margin-bottom: 20px;
        }

        .sortTitle {
            margin-bottom: 10px;

            @include media-breakpoint-up(lg) {
                font-weight: var(--font-weight-semibold);
            }
        }
    }

    .filters {
        position: relative;

        &--title {
            @include media-breakpoint-up(lg) {
                font-weight: var(--font-weight-semibold);
            }
        }

        .form-check-reverse {
            @include media-breakpoint-up(lg) {
                text-align: start;
            }

            .form-check-input {
                border-width: 1px;

                &:checked {
                    background-color: var(--color-base);
                    border-color: var(--color-base);
                }

                &:focus {
                    border-color: var(--color-base);
                    box-shadow: 0 0 0 0.25rem #29292e40;
                }

                @include media-breakpoint-up(lg) {
                    float: left;
                    margin-right: 0.5em;
                }

            }
        }

        .fa-caret-down {
            color: var(--color-text);
            margin-left: 5px;

            &--open {
                transform: rotate(180deg);
            }

            @include media-breakpoint-up(lg) {
                display: none;
            }
        }

        &--menu {
            position: absolute;
            padding: 10px 5px 10px 10px;
            background-color: var(--color-white);
            right: 0;
            z-index: 5;
            width: max-content;
            box-shadow: 2px 5px 4px rgba(0, 0, 0, 0.25);

            @include media-breakpoint-up(lg) {
                position: relative;
                box-shadow: none;
            }

            &.open {
                display: block;
            }

            &.closed {
                display: none;

                @include media-breakpoint-up(lg) {
                    display: block;
                }
            }
        }
    }
}
