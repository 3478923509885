#homeStory {
    margin: 10px auto;

    @include media-breakpoint-up(lg) {
        margin: 24px auto;
    }

    > *:not(:last-child) {
        margin-bottom: 6px;
    }

    p {
        text-align: justify;

        @include media-breakpoint-up(lg) {
            text-align: start;
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        @include media-breakpoint-down(lg) {
            text-align: center;
        }
    }

    img {
        height: 170px;
        object-fit: cover;
        border-radius: 10px;
        float: left;

        @include media-breakpoint-up(md) {
            height: 280px;
            margin-right: 40px;
        }

        @include media-breakpoint-up(lg) {
            height: 400px;
            max-width: 560px;
        }
    }
}
