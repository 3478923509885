/* Typography */
@import 'bootstrap/scss/mixins';

%h-1, .h-1 {
    color: var(--header-color-1);
    font: var(--h-1-mobile);

    @include media-breakpoint-up(md){
        font: var(--h-1-tablet);
    }

    @include media-breakpoint-up(lg){
        font: var(--h-1-desktop);
    }
}

%h-2, .h-2 {
    color: var(--header-color-1);
    font: var(--h-2-mobile);

    @include media-breakpoint-up(md){
        font: var(--h-2-tablet);
    }

    @include media-breakpoint-up(lg){
        font: var(--h-2-desktop);
    }
}

%h-3, .h-3 {
    color: var(--header-color-1);
    font: var(--h-3-mobile);

    @include media-breakpoint-up(md){
        font: var(--h-3-tablet);
    }

    @include media-breakpoint-up(lg){
        font: var(--h-3-desktop);
    }
}

%h-4, .h-4 {
    color: var(--header-color-1);
    font: var(--h-4-mobile);

    @include media-breakpoint-up(md){
        font: var(--h-4-tablet);
    }

    @include media-breakpoint-up(lg){
        font: var(--h-4-desktop);
    }
}

%h-5, .h-5 {
    color: var(--header-color-1);
    font: var(--h-5-mobile);

    @include media-breakpoint-up(md){
        font: var(--h-5-tablet);
    }

    @include media-breakpoint-up(lg){
        font: var(--h-5-desktop);
    }
}

%h-6, .h-6 {
    color: var(--header-color-1);
    font: var(--h-6-mobile);

    @include media-breakpoint-up(md){
        font: var(--h-6-tablet);
    }

    @include media-breakpoint-up(lg){
        font: var(--h-6-desktop);
    }
}

%p-l, .p-l {
    color: var(--body-color);
    font: var(--p-l-mobile);

    @include media-breakpoint-up(lg){
        font: var(--p-l-desktop);
    }
}

%p-m, .p-m {
    color: var(--body-color);
    font: var(--p-m-mobile);

    @include media-breakpoint-up(lg){
        font: var(--p-m-desktop);
    }
}

%p-s, .p-s {
    color: var(--body-color);
    font: var(--p-s-mobile);

    @include media-breakpoint-up(lg){
        font: var(--p-s-desktop);
    }
}

%link, .link {
    font: var(--p-m-mobile);
    color: var(--link-color);
    text-decoration: var(--link-decoration);

    &:hover, &:active, &:focus {
        color: var(--link-color-hover);
        text-decoration: var(--link-decoration-hover);
    }

    @include media-breakpoint-up(lg){
        font: var(--p-m-desktop);
    }
}

%tag, .tag {
    font: var(--tag-font-mobile);
    color: var(--tag-color);

    @include media-breakpoint-up(lg){
        font: var(--tag-font-desktop);
    }
}
