#team {
    .cards {
        display: flex;
        flex-direction: column;

        @include media-breakpoint-up(xl) {
            flex-direction: row;
            justify-content: space-between;
            flex-wrap: wrap;
        }
    }

    .card {
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;

        @include media-breakpoint-up(md) {
            flex-direction: row;
        }

        @include media-breakpoint-up(xl) {
            width: 49%;
            height: fit-content;
        }

        &-img {
            border-radius: 10px 10px 0 0;
            aspect-ratio: 355/285;
            object-fit: cover;

            @include media-breakpoint-up(md) {
                aspect-ratio: 1/1;
                width: 280px;
                height: 280px;
                border-radius: 10px 0 0 10px;
            }

            @include media-breakpoint-up(lg) {
                width: 240px;
                height: 240px;
            }
        }

        &-body {
            display: flex;
            flex-direction: column;
            padding: 5px 16px;

            :not(:last-child) {
                margin-bottom: 6px;
            }

            h2 {
                font-style: italic;

                @include media-breakpoint-up(md) {
                    font-size: rem(42px);
                }
            }

            span,
            a {
                font: var(--p-l-mobile);
            }

            svg {
                margin-right: 5px;
            }

            .no-whats {
                margin-left: 26px;
            }

            .details {
                text-align: end;
                margin-top: auto;
            }
        }
    }
}
