/* Bootstrap Variables Overrides */
@import 'variables';

/* Bootstrap Import*/
@import 'bootstrap/scss/bootstrap';

/* Bootstrap Skin Overrides */
.navbar {
    --bs-navbar-toggler-icon-bg: url('../images/navbar-toggle.svg');
    --bs-navbar-toggler-border-color: transparent;
}

.navbar-nav .nav-link.active {
    color: var(--header-color-primary);
}

.carousel-indicators {
    [data-bs-target] {
        background-color: var(--color-light);
    }

    .active {
        opacity: 0.8;
    }
}

.carousel-control-prev,
.carousel-control-next {
    color: var(--color-light);
    width: 5%;
}

.accordion {
    --bs-accordion-bg: #transparent;
    --bs-accordion-border-radius: 5px;
    --bs-accordion-border-color: var(--color-border);
    --bs-accordion-inner-border-radius: 5px;
    --bs-accordion-btn-padding-x: 10px;
    --bs-accordion-btn-padding-y: 8px;
    --bs-accordion-btn-focus-border-color: var(--color-base);
    --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem #29292e40;
    --bs-accordion-body-padding-x: 10px;
    --bs-accordion-body-padding-y: 10px;
}

.card {
    border: none;
    border-radius: 10px;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
}

.pagination {
    --bs-pagination-active-bg: none;
    --bs-pagination-active-color: var(--color-tertiary);
    --bs-pagination-active-border-color: none;
    --bs-pagination-focus-box-shadow: none;
    --bs-pagination-focus-bg: none;
}

:root {
    --bs-body-color: var(--color-text);
    --bs-body-bg: var(--color-white);
    --bs-link-color: var(--color-text);
    --bs-link-hover-color: var(--color-tertiary);
    --bs-border-width: 0;
    --bs-border-color: var(--color-border);
}
