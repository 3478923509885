body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

img {
    width: 100%;
}

p, li, span { @extend %p-m; }

a { @extend %link; }

h1 { @extend %h-1; }

h2 { @extend %h-2; }

h3 { @extend %h-3; }

h4 { @extend %h-4; }

h5 { @extend %h-5; }

h6 { @extend %h-6; }
