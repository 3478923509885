@import 'skinVariables';

/* Background Colors */

.bg-none {
    background-color: transparent!important;
}

.bg-white {
    background-color: var(--color-white)!important;
}

.bg-light {
    background-color: var(--color-light)!important;
}

.bg-dark {
    background-color: var(--color-base)!important;
}

.theme-dark {
    @extend .bg-dark;

    * {
        color: var(--color-primary);
    }

    a:hover {
        color: var(--color-primary-hover);
        text-decoration: var(--link-decoration);
    }
}

.theme-light {
    @extend .bg-light;

    * {
        color: var(--color-icon);
    }

    a:hover {
        color: var(--color-tertiary);
        text-decoration: var(--link-decoration);
    }
}
