#error {
    text-align: center;
    margin: auto;

    .error {
        &-content {
            margin-bottom: 60px;
        }

        &-sad {
            font-size: 96px;
            line-height: 114px;

            @include media-breakpoint-up(lg) {
                font-size: 128px;
                line-height: 152px;
            }
        }

        &-desc {
            font-size: 36px;
            font-style: italic;
            line-height: 46px;

            @include media-breakpoint-up(lg) {
                font-size: 64px;
                line-height: 83px;
            }
        }

        &-refresh {
            font-size: 20px;
            font-family: var(--header-font);
            cursor: pointer;

            @include media-breakpoint-up(lg) {
                font-size: 28px;
            }
        }
    }
}
