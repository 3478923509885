#homeBanner {
    .carousel-item {
        background-color: var(--color-base);

        img {
            height: 35vh;
            object-fit: cover;
            opacity: 0.95;

            @include media-breakpoint-up(lg) {
                height: 60vh;
            }
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: var(--color-white);
        font-style: italic;
        text-shadow: 2px 1px var(--color-base);
    }

    .carousel-caption {
        left: 7.5%;
        right: 7.5%;
        text-align: left;
    }
}
