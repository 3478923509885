#not-found {
    text-align: center;
    margin: auto;

    .not-found {
        &-content {
            margin-bottom: 80px;
        }

        &-ops {
            font-size: 96px;
            line-height: 124px;
            font-style: italic;

            @include media-breakpoint-up(lg) {
                font-size: 128px;
                line-height: 166px;
            }
        }

        &-message {
            font-size: 36px;
            line-height: 46px;
            font-style: italic;

            @include media-breakpoint-up(lg) {
                font-size: 64px;
                line-height: 83px;
            }
        }

        &-return {
            font: 20px/26px var(--header-font);

            @include media-breakpoint-up(lg) {
                font-size: 28px;
                line-height: 36px;
            }
        }
    }

}
