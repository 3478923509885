@import './articles/filterAndSort';

#articles {
    .wrapper {
        display: flex;
        flex-direction: column;

        @include media-breakpoint-up(lg) {
            flex-direction: row-reverse;
            justify-content: space-between;
        }
    }

    .content {
        margin: 20px 0;
        display: flex;
        flex-direction: column;
        align-items: center;

        .card:not(:last-child) {
            margin-bottom: 15px;
        }

        @include media-breakpoint-up(lg) {
            max-width: 78%;
            margin: 0;
        }

        .card {
            display: flex;
            flex-direction: column;
            max-width: 474px;

            .badge {
                position: absolute;
                top: 6px;
                left: 6px;
                font-family: var(--header-font);

                &--artigo {
                    background-color: var(--color-tertiary);
                }

                &--noticia {
                    background-color: var(--color-red);
                }

                &--palestra {
                    background-color: var(--color-base);
                }
            }

            @include media-breakpoint-up(lg) {
                max-width: unset;
                flex-direction: row;
                padding: 10px;
            }

            &-img-top {
                border-radius: 10px 10px 0 0;
                height: 200px;
                object-fit: cover;

                @include media-breakpoint-up(lg) {
                    border-radius: 10px;
                    height: 180px;
                    width: 280px;
                    margin-right: 30px;
                }

                @include media-breakpoint-up(xl) {
                    height: unset;
                    width: 360px;
                }
            }

            &-body {
                padding: 5px 10px;
                display: flex;
                flex-direction: column;

                @include media-breakpoint-up(lg) {
                    padding: 0;
                }

                &--title {
                    display: flex;
                    justify-content: space-between;
                    align-items: baseline;
                    text-align: justify;
                }

                &--text {
                    display: -webkit-box;
                    text-align: justify;
                    overflow: hidden;
                    -webkit-line-clamp: 8;
                    -webkit-box-orient: vertical;

                    @include media-breakpoint-up(lg) {
                        -webkit-line-clamp: 4;
                        text-align: start;
                    }

                    @include media-breakpoint-up(xl) {
                        -webkit-line-clamp: 5;
                    }
                }

                &--footer {
                    display: flex;
                    justify-content: space-between;
                    align-items: baseline;
                    margin-top: auto;

                    .date {
                        text-transform: capitalize;
                    }

                    a {
                        font-size: rem(14px);
                    }
                }
            }
        }
    }

    .pagination {
        display: flex;
        justify-content: center;

        @include media-breakpoint-up(md) {
            justify-content: end;
        }

        a {
            font-size: rem(18px);
            font-weight: var(--font-weight-light);
        }
        .active {
            font-weight: var(--font-weight-bold);
        }
    }
}
