.navbar {
    img {
        min-height: 38px;
        max-height: 45px;

        @include media-breakpoint-up(lg) {
            min-height: 50px;
            max-height: 60px;
        }
    }

    &--items {
        flex: 1 0;
        justify-content: end;
    }

    li {
        &:not(:last-child) {
            padding-right: 40px;
        }
    }

    &--link {
        color: var(--header-color-primary);
        text-align: end;

        &:hover {
            color: var(--header-color-primary-hover);
            text-decoration: none;
        }
    }
}
